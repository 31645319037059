<template>
  <section id="lesson-navigation">
    <v-card flat color="transparent">
      <v-card-text>
        <div class="text-h6" :class="dark == 1 ? 'white--text' : 'black--text'">
          Module Navigation
        </div>
        <v-expansion-panels class="mt-5">
          <v-expansion-panel
            v-for="item in course.lessons"
            :key="item.id"
            :readonly="item.type == 'Assessment'"
          >
            <v-expansion-panel-header>
              <template v-slot:default>
                <div class="d-flex flex-row align-center">
                  <div class="d-flex flex-column">
                    <div class="body-2 font-weight-bold" v-text="item.title" />
                    <div class="caption" v-text="`Lesson ${item.type}`" />
                  </div>
                  <v-spacer />
                  <v-icon
                    @click.stop="item.status == 1 ? learn(item.start) : ''"
                    v-if="item.type !== 'Assessment' && item.topics.length > 0"
                    class="mr-2"
                    :color="item.status == 1 ? 'info' : ''"
                    size="22"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                  <v-progress-circular
                    size="30"
                    :value="item.progress"
                    width="5"
                    :color="item.progress <= 50 ? 'warning' : 'success'"
                    class="mr-1"
                  >
                    <small style="font-size: 9px">{{ item.progress }}</small>
                  </v-progress-circular>
                </div>
              </template>
              <template v-slot:actions>
                <v-icon
                  @click="assessment(item)"
                  v-if="item.type == 'Assessment'"
                  size="22"
                  :class="item.assessment_count == 1 ? 'white--text' : ''"
                >
                  {{
                    item.assessment_count == 0
                      ? "mdi-order-bool-descending-variant"
                      : "mdi-file-outline"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content class="mt-3">
              <Topic :topics="item.topics" :start="item.start" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Topic from "./Topic.vue";

export default {
  props: ["course", "dark"],
  components: {
    Topic,
  },
  computed: {
    ...mapState("student", {
      topic: (state) => state.topic,
    }),
  },
  methods: {
    ...mapActions("student", ["topicsGetAction"]),

    learn(item) {
      if (item.status == 0) {
        alert("Next topic is not yet open");
        return;
      } else {
        this.$router.push({
          name: "Student Material",
          params: {
            uuid: item.uuid,
          },
        });
      }
    },
    assessment(item) {
      if (item.status == 1) {
        console.log(item.assessment.uuid);
        this.$router.push({
          name: "Student Assessment",
          params: { uuid: item.assessment.uuid, type: "lesson" },
        });
      } else {
        alert("Assessment is not yet open");
      }
    },
  },
};
</script>