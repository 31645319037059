<template>
  <section id="courses">
    <v-row v-if="!loading">
      <v-col cols="12" xl="9">
        <breadcrumbs :dark="user.dark" :links="links" />
        <v-card flat style="background-color: transparent">
          <v-row no-gutters>
            <v-col cols="12" lg="8" style="background-color: #fff">
              <v-card-text>
                <v-img
                  v-if="course.image"
                  height="520"
                  width="100%"
                  :src="`${course.aws}/courses/${course.uuid}/image/${course.image.image}`"
                />
                <v-card-title class="text-h5">
                  {{ course.title }}
                </v-card-title>
                <v-card-subtitle>
                  Teacher
                  {{ `${course.teacher.firstname} ${course.teacher.lastname}` }}
                </v-card-subtitle>

                <div class="mt-5 body-1" v-html="course.description"></div>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="4" class="" >
              <LessonNavigation :course="course" :dark="user.dark" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div v-else class="body-1">Loading...</div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import LessonNavigation from "../components/course/LessonNavigation.vue";

export default {
  components: {
    LessonNavigation,
  },
  data: () => ({
    loading: true,
    links: [],
  }),
  computed: {
    ...mapState("student", {
      course: (state) => state.course,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.$store
      .dispatch("student/courseGetAction", this.$route.params.uuid)
      .then(() => {
        this.links.push(
          {
            name: "My Courses",
            link: true,
            to: {
              name: "Student Courses",
              params: "",
            },
          },
          {
            name: this.course.title,
            link: false,
            to: {
              name: "",
              params: "",
            },
          }
        );
        this.loading = false;
      });
  },
};
</script>