var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-2"},[_c('v-expansion-panels',_vm._l((_vm.topics),function(topic){return _c('v-expansion-panel',{key:topic.id,attrs:{"readonly":""}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"body-2 font-weight-bold",domProps:{"textContent":_vm._s(topic.title)}}),_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(
                  topic.type == 'v'
                    ? 'Video'
                    : topic.type == 'd'
                    ? 'Document'
                    : topic.type == 't'
                    ? 'Virtual Tour'
                    : 'Youtube'
                )}})]),_c('v-spacer')],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":topic.is_complete == true ? 'success' : '#999',"size":"22"},on:{"click":function($event){$event.stopPropagation();return _vm.learn(topic)}}},[_vm._v(" mdi-play-circle-outline ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content')],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }