<template>
  <section class="mt-2">
    <v-expansion-panels>
      <v-expansion-panel v-for="topic in topics" :key="topic.id" readonly>
        <v-expansion-panel-header>
          <template v-slot:default>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <div class="body-2 font-weight-bold" v-text="topic.title" />
                <div
                  class="caption"
                  v-text="
                    topic.type == 'v'
                      ? 'Video'
                      : topic.type == 'd'
                      ? 'Document'
                      : topic.type == 't'
                      ? 'Virtual Tour'
                      : 'Youtube'
                  "
                />
              </div>
              <v-spacer></v-spacer>
            </div>
          </template>
          <template v-slot:actions>
            <v-icon
              @click.stop="learn(topic)"
              :color="topic.is_complete == true ? 'success' : '#999'"
              size="22"
            >
              mdi-play-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content> </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
export default {
  props: ["topics", "start"],
  methods: {
    learn(topic) {
      if (topic.status !== 1) {
        alert("Topic is not open");
        return;
      }
      this.$router.push({
        name: "Student Material",
        params: {
          uuid: topic.uuid,
        },
      });
    },
  },
};
</script>